import React from "react"
import Layout from '../components/layout'
import Slider from '../components/Slider'
import FeaturedVehicles from '../components/featured/FeaturedVehicles'
import SEO from '../components/SEO'
import VideoParagraph from '../components/video/VideoParagraphComponent'
import InstagramFeed from '../components/feed/InstagramFeed'
import AddtlButtons from '../components/additionalButtons/AddtlButtonsComponent'
import HTML from "../components/HTML"
import AlphaSlider from '../components/alphaSlider/AlphaSlider'
import Directions from "../components/directions/Directions"
import SearchBar from "../components/searchBar/SearchBar"
import ImageParagraph from "../components/image/ImageParagraphComponent"
import OurSpecials from '../components/ourspecials/OurSpecials'
import VehicleSlider from '../components/vehicleSlider/VehicleSlider'

function HomePage(props) {
    const { pageContext } = props
    const { pageContent, theme, dealerInfo, inventorymanagement,site_id, lead_url, headerTags } = pageContext

    let components = [
        <SEO key={"SEO"} title={"Home"} data={pageContent.SEO} tagString={headerTags}/>,
        <Slider key={"Slider"} data={pageContent.HeroSlider} theme={theme} />,
        <FeaturedVehicles key={"Featured"} data={pageContent.InventorySlider}
        theme={theme} featured={pageContent.InventorySlider.vins} 
        theSlug={inventorymanagement.vdpslug} 
        unavailableImg={inventorymanagement.unavailableImg}/>,
        <VideoParagraph key={"VideoParagraphComponent"} data={pageContent.About}
        theme={theme} />,
        <InstagramFeed data={pageContent.InstagramSection} dealerInfo={dealerInfo}/>,
        <AddtlButtons disclaimerContact={inventorymanagement.disclaimerContact} data={pageContent.AddtlButtonsComponent} leadURL={lead_url} site_id={site_id} />,
        <AlphaSlider key={"Slider"} data={pageContent.AlphaSlider} theme={theme} />,
        <SearchBar key={"searchBar"} data={pageContent.HomeSearchbar} />,
        <Directions key={"DirectionsHours"} 
            data={{...pageContent.DirectionsHours, ...dealerInfo }}
        />,
        <ImageParagraph  data={pageContent.ImageParagraphComponent}></ImageParagraph>,
        <OurSpecials data={pageContent.OurSpecials} theme={theme} />,
        <VehicleSlider key={"VehicleSlider"} data={pageContent.VehicleSlider} />
    ]
    
    if(pageContent.hasOwnProperty("ArrayRawHTML")) {
        const { arrayRawHTML } = pageContent.ArrayRawHTML
        const rawComponents = arrayRawHTML.map(element => {  
            return <HTML data={{ 
                order: Number(element.order),
                visible: element.visible,
                rawHTML: element.rawHtml,
                JSEnabled: element.JSEnabled
            }} key={"HTML"} />
        })
        components.push(...rawComponents)
    }

    let sortedComponents = components.sort((a, b) =>
        parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
    ) // sorts the components based on json data
    // filters out the components that are not visible
    let visibleComponents = sortedComponents.filter(component => {
        return component.props.data.visible === true
    })

    return (
        <Layout>
            { visibleComponents}
        </Layout>
    )
}

export default HomePage

        // <BrandsComponent key={"BrandsCompontnt"} data={pageContent.BrandsComponent}
        //     theme={theme} brands={pageContent.BrandsComponent.brands} />,
        // <OurSpecials data={pageContent.OurSpecials} theme={theme} />,
        // <FeaturedComponent key={"Featured"} data={pageContent.FeaturedComponent}
        //     theme={theme} featured={pageContent.FeaturedComponent.featured} />,
        // <AboutComponent key={"AboutComponent"} data={pageContent.AboutComponent}
        //     about={pageContent.AboutComponent.about[0]} theme={theme} />,
